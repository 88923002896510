import { toast } from 'react-toastify';
import axios from './axios-client';

export const getPlans = async (setLoading = f => f, setData = f => f, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get(`plan`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};
