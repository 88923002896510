import React from 'react';
import css from './ClubsPage.module.css';
import sommly1 from './sommly1.png'
import sommly2 from './sommly2.png'
import sommly3 from './sommly3.png'
import sommly4 from './sommly4.png'
import sommly5 from './sommly5.png'
import sommly6 from './sommly7.png'

const ClubPlans = ({plans, plansLoading, handleOnClickSubscribe, handleOnClickSubscribeMinimal, handleOnClickSubscribeBigReds, handleOnClickSubscribeAwardWinners, handleOnClickSubscribeMixItUp,handleOnClickSubscribeRoseAndBubbles,  handlePortal}) => {
  if(plansLoading) return <p>Loading...</p>
  return (
    <div className={css.plansWrapper}>
        
        <div className={css.plan}>
        <img src={sommly1} height="200" width="275"/>
          <div>
          
            <div className={css.contentHeader}>
              <h1 style={{ fontSize: "31px" }}>Single Varietals</h1>
              <h2>$120/mo</h2>
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: This membership is perfect for wine lovers who love to experience each variety uniquely to get the full expression of the grape and terroir. Enjoy your old favorites, and learn some that are up and coming.</p>
            <ul>
            <li key={1}>Monthly shipments of 2-3 bottles.</li>
            
            </ul>
          </div>
          <div className={css.planBtnWrapper}>
              <button
                onClick={handleOnClickSubscribe}
                className={css.planBtn}
              >
                Subscribe
              </button>
          </div>
        </div>

        <div className={css.plan}>
        <img src={sommly2} height="200" width="275" />
          <div>
            <div className={css.contentHeader}>
              <h1 style={{ fontSize: "31px" }}>Minimal intervention</h1>
              <h2>$120/mo</h2>
              {/* <span>{plans[1].quotes[0]}</span> */}
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: These wines are produced with minimal intervention, and might be referred to as "natural" wines. This style of production lets the fruit express itself most fully, without outside influences.</p>
            <ul>
            <li key={2}>Monthly shipments of 2-3 bottles.</li>
            </ul>
          </div>

          <div className={css.planBtnWrapper}>
              <button
                onClick={handleOnClickSubscribeMinimal}
                className={css.planBtn}
              >
                Subscribe
              </button>
          </div>
        </div>

        <div className={css.plan}>
          <img src={sommly3} height="200" width="275" />
          <div>
            <div className={css.contentHeader}>
              <h1 style={{ fontSize: "31px" }}>Big Reds</h1>
              <h2>$160/mo</h2>
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: There's something to be said about those who know exactly what they like. If you love big, bold reds, this is the club for you!</p>
            <ul>
            <li key={3}>Monthly shipments of 2-3 bottles.</li>
            </ul>
          </div>
          <div className={css.planBtnWrapper}>
              <button
                className={css.planBtn}
                onClick={handleOnClickSubscribeBigReds}
              >
                Subscribe
              </button>

          </div>
        </div>
        <div className={css.plan}>
          <img src={sommly4} height="200"  width="275"/>
          <div>
            <div className={css.contentHeader}>
              <h1 style={{ fontSize: "31px" }}>Award Winners</h1>
              <h2>$160/mo</h2>
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: These wines have all been awarded medals at US and international competitions, which means that they have been blind tasted by a panel of impartial judges. Find out what the fuss is all about!</p>
            <ul>
            <li key={4}>Monthly shipments of 2-3 bottles.</li>
            </ul>
          </div>
          <div className={css.planBtnWrapper}>
              <button
                className={css.planBtn}
                onClick={handleOnClickSubscribeAwardWinners}
              >
                Subscribe
              </button>

          </div>
          
        </div>

        <div className={css.plan}>
          <img src={sommly5} height="200"  width="275"/>
          <div>
            <div className={css.contentHeader}>
            <h1 style={{ fontSize: "31px" }}>Mix it up</h1>
              <h2>$120/mo</h2>
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: Do you want our Sommeliers to send you our staff favorites each month, regardless of the varietal, style, or geography? This is the club for you, especially if you prefer variety over redundancy.</p>
            <ul>
            <li key={5}>Monthly shipments of 2-3 bottles.</li>
            </ul>
          </div>
          <div className={css.planBtnWrapper}>
              <button
                className={css.planBtn}
                onClick={handleOnClickSubscribeMixItUp}
              >
                Subscribe
              </button>

          </div>
          
        </div>

        <div className={css.plan}>
          <img src={sommly6} height="200"  width="275"/>
          <div>
            <div className={css.contentHeader}>
              <h1 style={{ fontSize: "31px" }}>Rosé and Bubbles</h1>
              <h2>$120/mo</h2>
            </div>
            <p style={{ fontSize: "16px" }}>Why We Love It: Rosé pairs with everything, especially summertime. And bubbles make every occasion a celebration. Our Sommeliers curate you a selection of rosé and sparkling wine each month to share with friends and fam.</p>
            <ul>
            <li key={6}>Monthly shipments of 2-3 bottles.</li>
            </ul>
          </div>
          <div className={css.planBtnWrapper}>
              <button
                className={css.planBtn}
                onClick={handleOnClickSubscribeRoseAndBubbles}
              >
                Subscribe
              </button>

          </div>
          
        </div>
        {/* <div className={css.planBtnWrapper}>
              <button
                onClick={handlePortal}
                className={css.planBtn}
              >
                <h1 style={{ fontSize: "19px" }}>Manage Subscriptions</h1>
              </button>
          </div> */}
      </div>
  )
}
export default ClubPlans;
