import React, { useEffect, useState } from 'react';
import axios from "axios";
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  Modal,
  NamedLink,
} from '../../components';
import {BottleRatingReview} from '../../components/review/Bottle';

import StaticPage from '../../containers/StaticPage/StaticPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './ClubsPage.module.css';
import pattern from './pattern.png';
import pattern1 from './pattern1.png';
import ClubPlans from './ClubPlans';
import { getPlans } from '../../services/plan';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


const WineryPlansPage = ({
  currentUser,
  wineryOwner,
  admin,
  onManageDisableScrolling,
  history,
}) => {
  const [plans, setPlans] = useState([]);
  const [plansLoading, setPlansLoading] = useState([]);
  const [tosModalOpen, setTosModelOpen] = useState(false);
  const [PortaltosModalOpen, PortalsetTosModelOpen] = useState(false);

  useEffect(() => {
    getPlans(setPlansLoading, setPlans)
  }, []);

  const handleOnClickSubscribe = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }

      console.log(userid)

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        async hostedPage()  {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
        //  return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Single-Varietals-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid + "&clubplan=Single-Varietals-USD-Monthly" ).then((response) => response.data)
        },
        async success(hostedPageId) {
          console.log("SUCCESS!!!")
          //axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
          axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid + "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
          window.location.href = 'https://sommly.com/signup';
          }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
   // }
  }

  const handleOnClickSubscribeBigReds = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }
  //  if (currentUser === null){
   //   console.log("not logged in")
  //    setTosModelOpen(true)
 //   }
  //  else
 //   {
      //console.log(currentUser["expresUserId"])
    

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        hostedPage: () => {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
          //return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Big-Reds-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid + "&clubplan=Big-Reds-USD-Monthly" ).then((response) => response.data)
        },
        success(hostedPageId) {
          console.log("SUCCESS!!!")
          //axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
          axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid + "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
            window.location.href = 'https://sommly.com/signup';
            }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
   // }
  }

  const handleOnClickSubscribeAwardWinners = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }
  //  if (currentUser === null){
   //   console.log("not logged in")
   //   setTosModelOpen(true)
  //  }
  //  else
  //  {
    //  console.log(currentUser["expresUserId"])
    

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        hostedPage: () => {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
          //return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Award-Winners-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid + "&clubplan=Award-Winners-USD-Monthly" ).then((response) => response.data)
        },
        success(hostedPageId) {
          console.log("SUCCESS!!!")
         // axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
         axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid+ "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
            window.location.href = 'https://sommly.com/signup';
            }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
  //  }
  }

  const handleOnClickSubscribeMixItUp = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }
  //  if (currentUser === null){
   //   console.log("not logged in")
  //    setTosModelOpen(true)
  //  }
  //  else
   // {
     // console.log(currentUser["expresUserId"])
    

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        hostedPage: () => {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
         // return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Mix-it-up-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid+ "&clubplan=Mix-it-up-USD-Monthly" ).then((response) => response.data)
        },
        success(hostedPageId) {
          console.log("SUCCESS!!!")
        //  axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
        axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid+ "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
            window.location.href = 'https://sommly.com/signup';
            }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
  //  }
  }

  const handleOnClickSubscribeRoseAndBubbles = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }
  //  if (currentUser === null){
   //   console.log("not logged in")
 //     setTosModelOpen(true)
  //  }
 //   else
 //   {
    //  console.log(currentUser["expresUserId"])
    

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        hostedPage: () => {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
          //return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Rose-and-Bubbles-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid + "&clubplan=Rose-and-Bubbles-USD-Monthly" ).then((response) => response.data)
        },
        success(hostedPageId) {
          console.log("SUCCESS!!!")
         // axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
         axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid + "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
            window.location.href = 'https://sommly.com/signup';
            }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
   // }
  }

  const handleOnClickSubscribeMinimal = () => {
    console.log(currentUser);
    console.log(wineryOwner);
    if (currentUser === null){
      var newuser = true
      var userid = Math.random()
      console.log("not logged in")
     // setTosModelOpen(true)
    }
    else
    {
      var newuser = false
      var userid = currentUser["expresUserId"]
    }
  //  if (currentUser === null){
 //     console.log("not logged in")
 //     setTosModelOpen(true)
  //  }
  //  else
  //  {
    //  console.log(currentUser["expresUserId"])
    

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

      cbInstance.openCheckout({
        hostedPage: () => {
          //return axios.get("https://793pvp2tv4.execute-api.us-east-1.amazonaws.com/PROD/gethostedpage?userid=" + currentUser["expresUserId"] + "&plan=123" ).then((response) => response.data)
          //return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + currentUser["expresUserId"] + "&clubplan=Minimal-Intervention-USD-Monthly" ).then((response) => response.data)
          return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/checkout_new?user=" + userid + "&clubplan=Minimal-Intervention-USD-Monthly" ).then((response) => response.data)
        },
        success(hostedPageId) {
          console.log("SUCCESS!!!")
          //axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + currentUser["expresUserId"] + "&hostedpageid=" + hostedPageId )
          axios.get(" https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/retreivehostedpage?user=" + userid + "&hostedpageid=" + hostedPageId )
          console.log(hostedPageId);
          if (newuser == true) {
            window.location.href = 'https://sommly.com/signup';
            }
        },
        close:() => {
          //this.setState({loading: false});
          console.log("checkout new closed");
        },
        step(step) {
          console.log("checkout", step);
        }
      });
   // }
  }

  const handlePortal = () => {
   if (currentUser === null){
      console.log("not logged in")
      setTosModelOpen(true)
    }
    else
    {

      var cbInstance = Chargebee.init({
        site: "site-name", // your test site
        domain: "https://sommly.com" // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })
      try {
        cbInstance.setPortalSession(
          async() => {
            return axios.get("https://etc2qwi843.execute-api.us-east-1.amazonaws.com/PROD/portal?user=" + currentUser["expresUserId"] ).then((response) => response.data)
          }
        );
        cbInstance.createChargebeePortal().open({
          visit(visit) {
            console.log("portal visit", visit);
          }
        });
        
      } catch (error) {
        console.log(error.message);
        PortalsetTosModelOpen(true)
      }
  }
  }

  return (
    <StaticPage
      title="Winery Clubs"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WineryPlansPage',
        description: 'Winery Plans Page',
        name: 'WineryPlansPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>

            <div className={css.layoutWrapperHeader}>
              <div className={css.overlay} />
              <div className={css.headerContent}>
                <h1>Curated Wine Membership</h1>
                <h3>
                  Monthly shipments of limited production wine, curated by our Sommeliers just for you.
                </h3>
              </div>
            </div>

            <div className={css.secondSectionWrapper}>
              <div className={css.pattern}>
                <img src={pattern} alt="" />
              </div>

              <div className={css.secondSectionContainer}>
                <h4>
                  Each allocation will be shipped to your doorstep, and will include a video tasting experience. Members get direct access to exclusive events and discounts from that month's featured producer on additional orders placed. Cancel anytime, and free to join. Free shipping.
                </h4>
              </div>
            </div>

            <div className={css.sommlyPlansWrapper}>
            <div className={css.thirdSectionContainer}>
                <h1>
                  Bring the winery experience home by joining or gifting a membership!
                </h1>
              </div>
              <ClubPlans
                plans={plans}
                plansLoading={plansLoading}
                handleOnClickSubscribe={handleOnClickSubscribe}
                handleOnClickSubscribeMinimal={handleOnClickSubscribeMinimal}
                handleOnClickSubscribeBigReds={handleOnClickSubscribeBigReds}
                handleOnClickSubscribeAwardWinners={handleOnClickSubscribeAwardWinners}
                handleOnClickSubscribeMixItUp={handleOnClickSubscribeMixItUp}
                handleOnClickSubscribeRoseAndBubbles={handleOnClickSubscribeRoseAndBubbles}
                handlePortal={handlePortal}
              />
            </div>
          </div>

          <Modal
            id="subscription"
            isOpen={tosModalOpen}
            onClose={() => setTosModelOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
                <h4>
                  Please sign up or login to manage your club subscriptions. Email tom@sommly.com for further inquiries.
                </h4>
                <NamedLink name="LoginPage">
                  Login here
                </NamedLink>
            </div>
          </Modal>
          <Modal
            id="portalsubscription"
            isOpen={PortaltosModalOpen}
            onClose={() => PortalsetTosModelOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
                <h4>
                  You do not have any club subscriptions yet.
                </h4>
            </div>
          </Modal>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { admin, wineryOwner } = state.Auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    admin,
    wineryOwner,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  // injectIntl
)(WineryPlansPage);
// export default WineryPlansPage;
